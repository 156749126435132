<template>
  <v-container
    id="usermanagement-roles"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-account-multiple"
      title="CLIENTS"
      class="px-5 py-3"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <v-text-field
          v-model="submit"
          full-width
          :label="$t('search')"
          @keyup.enter="search"
        />
      </v-col>

      <v-breadcrumbs
        :append="false"
        large
        divider=""
      >
        <v-breadcrumbs-item
          v-for="item in breadcrumbs"
          :key="item.text"
        >
          <a
            href="#"
            @click.prevent="findTrue(item)"
          >{{ item.text }}</a>
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <v-client-table
        v-if="tableData.length"
        ref="table"
        :data="tableData"
        :columns="table.columns"
        :options="table.options"
      >
        <!--  Action-->
        <template
          slot="action"
          slot-scope="props"
        >
          <!--  Edit-->
          <v-btn
            fab
            dark
            x-small
            color="success"
            @click="edit(props)"
          >
            <v-icon dark>
              {{ props.row.isOpen && props.row.edit ? 'mdi-pencil-plus' : 'mdi-pencil' }}
            </v-icon>
          </v-btn>
          <!--  Contrat-->
          <v-btn
            class="ml-2"
            fab
            light
            x-small
            color="white"
            @click="showContracts(props)"
          >
            <v-icon>{{ props.row.isOpen && !props.row.edit ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
          </v-btn>
        </template>
        <!--  Icon-->
        <template
          slot="type"
          slot-scope="props"
        >
          <!--  Administateur-->
          <template v-if="props.row.admin">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  color="green darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-script-text-outline
                </v-icon>
              </template>
              <span>{{ $t('Administrator') }}</span>
            </v-tooltip>
          </template>
          <!--  Type (Retraité, ex-employé)-->
          <!--  Employé-->
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="props.row.type === '1'"
                color="green"
                v-bind="attrs"
                v-on="on"
              >
                mdi-account
              </v-icon>
            </template>
            <span>{{ $t('client.Retirement') }}</span>
          </v-tooltip>
          <!--  Retraité-->
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="props.row.type === '2'"
                color="blue"
                v-bind="attrs"
                v-on="on"
              >
                mdi-sail-boat
              </v-icon>
            </template>
            <span>{{ $t('client.Retirement') }}</span>
          </v-tooltip>
          <!--  Ex-Employé-->
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="props.row.type === '3'"
                color="gray"
                v-bind="attrs"
                v-on="on"
              >
                mdi-watch-export
              </v-icon>
            </template>
            <span>{{ exEmploye(props.row) }}</span>
          </v-tooltip>
        </template>
        <!--  Nom de la personne-->
        <template
          slot="nomPrenom"
          slot-scope="props"
        >
          <template v-if="props.row.blacklist">
            <span class="font-weight-bold red--text">[ BLACKLIST ] </span>
            <strike
              v-if="checkIfInactif(props.row.Contrats)"
              class="red--text"
            >
              {{ props.row.nomPrenom }}
            </strike>
            <span v-else>{{ props.row.nomPrenom }}</span>
          </template>
          <template v-else>
            <strike
              v-if="checkIfInactif(props.row.Contrats)"
              class="red--text"
            >
              {{ props.row.nomPrenom }}
            </strike>
            <span v-else>{{ props.row.nomPrenom }}</span>
          </template>
        </template>
        <!--  Child Row-->
        <template #child_row="props">
          <template v-if="props.row.edit">
            <!--  Edition d'un client-->
            <contract-create-edit
              :create-edit-info="createEditInfo"
              :client-id="props.row.id"
              @create-update-client="createUpdateClient"
              @cancel-edit-client="cancelEditClient"
            />
          </template>
          <template v-else>
            <br>
            <v-btn-toggle
              v-model="props.row.factureAutre"
              mandatory
            >
              <v-btn
                :value="false"
                text
                color="primary"
                @click="ShowHideFactureAutre(props.index, false)"
              >
                {{ $t('sidebar.my_contracts') }}
              </v-btn>
              <v-btn
                :value="true"
                text
                color="primary"
                @click="ShowHideFactureAutre(props.index, true)"
              >
                <v-icon
                  v-if="props.row.unpaidUnvoiceState"
                  left
                  dark
                  color="error accent-3"
                >
                  {{ $t('invoices.Other_invoices') }}
                  mdi-alert
                </v-icon>
                {{ $t('invoices.Other_invoices') }}
              </v-btn>
            </v-btn-toggle>
            <!--  Facture autre-->
            <facture-autre
              v-show="props.row.factureAutre"
              :client-id="props.row.id"
              @unpaid-invoice="unpaidUnvoice"
            />
            <!--  Contrat-->
            <contract
              v-show="!props.row.factureAutre"
              :client-id="props.row.id"
            />
          </template>
          <br>
        </template>
      </v-client-table>
    </base-material-card>

    <v-dialog
      v-model="dialogNewClient"
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          <span class="text-h2">{{ $t('client.New_client') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialogNewClient = false"
          >
            mdi-close
          </v-icon>
          <div class="mt-2">
            <v-row
              justify="center"
              align="center"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              justify="center"
              align="center"
            >
              <v-col cols="12">
                <validation-observer
                  ref="obs"
                  v-slot="{ handleSubmit }"
                >
                  <base-material-card
                    inline
                    icon="mdi-account-outline"
                    :title="$t('client.Client_Information')"
                    class="px-5 py-3"
                  >
                    <form>
                      <!--  Username-->
                      <v-row
                        align="center"
                        dense
                      >
                        <v-col
                          class="text-right body-1 grey--text mr-4"
                          cols="2"
                        >
                          <span>{{ $t('client.Username') }}</span>
                        </v-col>

                        <v-col cols="8">
                          <validation-provider
                            v-slot="{ errors, valid }"
                            :name="$t('client.Username')"
                            rules="required"
                          >
                            <!--  Editable-->
                            <v-text-field
                              v-model="submitFormNewClient.username"
                              :error-messages="errors"
                              :success="valid"
                              required
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <!--  Pénom-->
                      <v-row
                        align="center"
                        dense
                      >
                        <v-col
                          class="text-right body-1 grey--text mr-4"
                          cols="2"
                        >
                          <span>{{ $t('FirstName') }}</span>
                        </v-col>

                        <v-col cols="8">
                          <validation-provider
                            v-slot="{ errors, valid }"
                            :name="$t('FirstName')"
                            rules="required"
                          >
                            <!--  Editable-->
                            <v-text-field
                              v-model="submitFormNewClient.prenom"
                              :error-messages="errors"
                              :success="valid"
                              required
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <!--  Nom-->
                      <v-row
                        align="center"
                        dense
                      >
                        <v-col
                          class="text-right body-1 grey--text mr-4"
                          cols="2"
                        >
                          <span>{{ $t('Name') }}</span>
                        </v-col>

                        <v-col cols="8">
                          <validation-provider
                            v-slot="{ errors, valid }"
                            :name="$t('Name')"
                            rules="required"
                          >
                            <!--  Editable-->
                            <v-text-field
                              v-model="submitFormNewClient.nom"
                              :error-messages="errors"
                              :success="valid"
                              required
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <!--  Courriel-->
                      <v-row
                        align="center"
                        dense
                      >
                        <v-col
                          class="text-right body-1 grey--text mr-4"
                          cols="2"
                        >
                          <span>{{ $t('client.Email') }}</span>
                        </v-col>

                        <v-col cols="8">
                          <validation-provider
                            v-slot="{ errors, valid }"
                            :name="$t('client.Email')"
                            rules="required|email"
                          >
                            <!--  Editable-->
                            <v-text-field
                              v-model="submitFormNewClient.email"
                              :error-messages="errors"
                              :success="valid"
                              required
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <!--  Bouton submit-->
                      <v-btn
                        color="success"
                        class="ml-3"
                        @click="handleSubmit(submitNewClient)"
                      >
                        {{ $t('Validate') }}
                      </v-btn>
                    </form>
                  </base-material-card>
                </validation-observer>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Components
  import Contract from '@/components/Client/Contract'
  import FactureAutre from '@/components/Client/Facture/FactureAutre'
  import ContractCreateEdit from '@/components/Client/ClientCreateEdit'
  // Service
  import ClientsService from '@/services/01Cell/ClientsService'
  // Mixins
  import { ClientEdit } from '@/mixins/Client/ClientEdit'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { mapGetters } from 'vuex'
  import { parseISO, differenceInCalendarDays } from 'date-fns'
  import { fr, enUS } from 'date-fns/esm/locale'
  window.dateFnsLocales = {
    fr,
    en: enUS,
  }
  export default {
    name: 'OperationClient',
    components: {
      Contract,
      FactureAutre,
      ContractCreateEdit,
      ValidationObserver,
      ValidationProvider,
    },
    mixins: [ClientEdit],
    data () {
      return {
        submit: '',
        tableData: [],
        createEditInfo: {
          icon: 'mdi-pencil',
          title: `${this.$i18n.t('Edit')}`,
        },
        dialogNewClient: false,
        submitFormNewClient: {
          username: null,
          prenom: null,
          nom: null,
          type: '0', // Type temporaire. Quand le client va se connecter la première fois, un remets le type à 1 et on mets à jour les infos du client. Voir le composant 'Dashboard'
          langue: null,
          email: null,
        },
        breadcrumbs: [
          {
            text: '[ Black list ]',
            href: '#',
            champs: 'blacklist',
          },
          {
            text: '[ Client Admin ]',
            href: '#',
            champs: 'admin',
          },
          {
            text: `[ ${this.$i18n.t('client.New_client')} ]`,
            href: '#',
            champs: 'newClient',
          },
        ],
        table: {
          columns: ['action', 'type', 'nomPrenom'],
          options: {
            sortable: ['nomPrenom'],
            filterByColumn: false,
            filterable: ['nomPrenom'],
            pagination: { chunk: 5 },
            perPage: 25,
            perPageValues: [10, 25, 50, 100],
            showChildRowToggler: false, // Si il y a des child row, sert pour cacher la colonne + pour afficher le child row. On utilise la fonction 'tableRowClick' plus bas
            texts: {
              count: this.$i18n.t('vueTables.texts.Showing') + ' {from} ' + this.$i18n.t('vueTables.texts.to') + ' {to} ' + this.$i18n.t('vueTables.texts.of') + ' {count} ' + this.$i18n.t('vueTables.texts.Records').toLowerCase() + '|{count} ' + this.$i18n.t('vueTables.texts.Records').toLowerCase() + '|' + this.$i18n.t('vueTables.texts.One_record'),
              first: this.$i18n.t('vueTables.texts.First'),
              last: this.$i18n.t('vueTables.texts.Last'),
              filter: this.$i18n.t('vueTables.texts.Filter') + ':',
              filterPlaceholder: this.$i18n.t('vueTables.texts.Search_query'),
              limit: this.$i18n.t('vueTables.texts.Records'),
              page: 'Page:',
              noResults: this.$i18n.t('vueTables.texts.No_matching_records'),
              filterBy: this.$i18n.t('vueTables.texts.Filter_by') + ' {column}',
              loading: this.$i18n.t('vueTables.texts.Loading'),
              defaultOption: this.$i18n.t('vueTables.texts.Select') + ' {column}',
              columns: this.$i18n.t('vueTables.texts.Columns'),
            },
            headings: {
              nomPrenom: this.$i18n.t('Name'),
            },
            columnsClasses: {
              action: 'VueTables-width-action-column text-left', // Voir dans le fichier 'style.scss'
              type: 'VueTables-width-icon-column text-right', // Voir dans le fichier 'style.scss'
            },
            sortIcon: {
              is: 'fa-sort',
              base: 'fas',
              up: 'fa-sort-up',
              down: 'fa-sort-down',
            },
          },
        },
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterLanguage: 'getLanguage',
      }),
    },

    methods: {
      checkIfInactif (contrats) {
        // Si tout les contrats sont inactif, ont mets un strike dans le html
        return contrats.every(item => item.statut === 4)
      },
      async findTrue (item) {
        if (item.champs) {
          if (item.champs === 'newClient') {
            // Reset des infos
            this.submitFormNewClient.username = null
            this.submitFormNewClient.nom = null
            this.submitFormNewClient.prenom = null
            this.submitFormNewClient.email = null
            // Ouvre le dialog
            this.dialogNewClient = true
            return
          }
          // Start Spinner
          this.$loading.show()
          try {
            const tableData = await ClientsService.queryTrue(item.champs)
            this.tableData = tableData.map(item => {
              item.edit = false
              item.nomPrenom = `[${item.id}] ${item.nom.toUpperCase()}, ${item.prenom[0].toUpperCase() + item.prenom.substr(1)}`
              return item
            })

            // Stop Spinner
            this.$loading.hide()
          } catch (error) {
            console.log(error)
            // Stop Spinner
            this.$loading.hide()
          }
        } else {
          console.log('outils')
        }
      },
      exEmploye (item) {
        if (item.date_ex_employe) {
          const dateExEmployee = item.date_ex_employe ? parseISO(item.date_ex_employe) : ''
          let day = this.$i18n.t('time.day')
          let remaining = this.$i18n.t('Remaining').toLowerCase()
          const dateRemaining = Math.abs(differenceInCalendarDays(new Date(), dateExEmployee) - this.jourMaxExEmployee)
          if (dateRemaining > 1) {
            day = `${this.$i18n.t('time.day')}s`
            remaining = `${this.$i18n.t('Remaining').toLowerCase()}s`
            return `${this.$i18n.t('client.Ex-employee')} (${dateRemaining} ${day} ${remaining})`
          }
        }
        return this.$i18n.t('client.Ex-employee')
      },
      async search () {
        if (this.submit.length === 0) return
        // Start Spinner
        this.$loading.show()
        try {
          // Reset table
          this.tableData = []

          const tableData = await ClientsService.search(this.submit)
          this.tableData = tableData.map(item => {
            item.unpaidUnvoiceState = false
            item.factureAutre = false
            item.edit = false
            item.isOpen = false
            item.nomPrenom = `[${item.id}] ${item.nom.toUpperCase()}, ${item.prenom[0].toUpperCase() + item.prenom.substr(1)}`
            return item
          })

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      edit (props) {
        // Sert pour l'îcone du bouton
        this.tableData[props.index - 1].isOpen = !this.tableData[props.index - 1].isOpen
        this.tableData[props.index - 1].edit = true
        this.$refs.table.toggleChildRow(props.row.id) // replace myTable with your own ref
      },
      showContracts (props) {
        // Sert pour l'îcone du bouton
        this.tableData[props.index - 1].isOpen = !this.tableData[props.index - 1].isOpen
        this.tableData[props.index - 1].edit = false
        this.$refs.table.toggleChildRow(props.row.id) // replace myTable with your own ref
      },
      ShowHideFactureAutre (index, state) {
        this.tableData[index - 1].factureAutre = state
      },
      async createUpdateClient (event) {
        // Start Spinner
        this.$loading.show()
        try {
          await ClientsService.update(event, event.id)
          // // Stop Spinner
          // this.$loading.hide()
          // Montre la confirmation
          this.$notifier.show({ message: this.$i18n.t('client.snackBar.client_updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })
          // Close child row
          this.$refs.table.toggleChildRow(event.id)
          this.search()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async cancelEditClient (id) {
        // Close child row
        this.$refs.table.toggleChildRow(id)
      },
      unpaidUnvoice (state, clientId) {
        // On mets à jour l'icône dans le bouton 'Autre factures'. On affiche l'icône si il y a des factures impayées
        const findClientIndex = this.tableData.findIndex(item => item.id === clientId)
        this.tableData[findClientIndex].unpaidUnvoiceState = state
      },
      async submitNewClient () {
        // Start Spinner
        this.$loading.show()
        try {
          // Langue du système par défaut
          this.submitFormNewClient.langue = this.parameterLanguage ? this.parameterLanguage : 'fr'
          // Création du client
          await ClientsService.save(this.submitFormNewClient)
          // Ferme le dialog
          this.dialogNewClient = false

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
