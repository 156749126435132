var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"inline":"","icon":_vm.createEditInfo.icon,"title":((_vm.createEditInfo.title) + ": [" + _vm.clientId + "] Client"),"color":"primary"}},[_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" Information ")]),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('client.Username'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.Username'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.username),callback:function ($$v) {_vm.$set(_vm.submitForm, "username", $$v)},expression:"submitForm.username"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" Type* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.types,"item-text":"item","item-value":"valeur","persistent-hint":"","single-line":"","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.type),callback:function ($$v) {_vm.$set(_vm.submitForm, "type", $$v)},expression:"submitForm.type"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('client.Sex'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.Sex'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.sexes,"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.sexe),callback:function ($$v) {_vm.$set(_vm.submitForm, "sexe", $$v)},expression:"submitForm.sexe"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('Name'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.nom),callback:function ($$v) {_vm.$set(_vm.submitForm, "nom", $$v)},expression:"submitForm.nom"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('FirstName'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('FirstName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.prenom),callback:function ($$v) {_vm.$set(_vm.submitForm, "prenom", $$v)},expression:"submitForm.prenom"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('client.Primary_phone'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.Primary_phone'),"rules":{ required: true, regex: _vm.regexPhoneNumber }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.telephone1),callback:function ($$v) {_vm.$set(_vm.submitForm, "telephone1", $$v)},expression:"submitForm.telephone1"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('client.Secondary_phone'))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.Secondary_phone'),"rules":{ regex: _vm.regexPhoneNumber }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid},model:{value:(_vm.submitForm.telephone2),callback:function ($$v) {_vm.$set(_vm.submitForm, "telephone2", $$v)},expression:"submitForm.telephone2"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('client.Email'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.email),callback:function ($$v) {_vm.$set(_vm.submitForm, "email", $$v)},expression:"submitForm.email"}})]}}],null,true)})],1)],1),_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('client.Accounting_administration'))+" ")]),(_vm.$company === 'uqam')?[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('Administrator'))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-checkbox',{model:{value:(_vm.submitForm.admin),callback:function ($$v) {_vm.$set(_vm.submitForm, "admin", $$v)},expression:"submitForm.admin"}})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('client.UBR_List'))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"UBR","rules":{ min:_vm.longueurCodeFacturation, max: _vm.longueurCodeFacturation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"color":"secondary","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":"secondary","close":"","small":""},on:{"click":select,"click:close":function($event){return _vm.removeUBR(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true),model:{value:(_vm.submitForm.admin_code_facturation_liste),callback:function ($$v) {_vm.$set(_vm.submitForm, "admin_code_facturation_liste", $$v)},expression:"submitForm.admin_code_facturation_liste"}}),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]:_vm._e(),_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('Other'))+" ")]),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" Blacklist ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-checkbox',{model:{value:(_vm.submitForm.blacklist),callback:function ($$v) {_vm.$set(_vm.submitForm, "blacklist", $$v)},expression:"submitForm.blacklist"}})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('client.Date_ex_employee'))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedDateFormattedDatefns,"clearable":"","readonly":""},on:{"click:clear":function($event){_vm.submitForm.date_ex_employe = null}}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":_vm.language,"min":_vm.nowDate},on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.submitForm.date_ex_employe),callback:function ($$v) {_vm.$set(_vm.submitForm, "date_ex_employe", $$v)},expression:"submitForm.date_ex_employe"}})],1)],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}}),(_vm.submitForm.date_ex_employe)?_c('v-col',{attrs:{"cols":"8"}},[_c('label',{staticClass:"red--text text--darken-2"},[_vm._v(_vm._s(_vm.getDaysExEmploye))])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"body-2 font-weight-light"},[_vm._v(" *"+_vm._s(_vm.$t('Required_Fields'))+" ")])]),_c('div',{staticClass:"pa-3 text-center"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t('Validate'))+" ")])],1)],2)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }